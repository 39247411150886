<template>
    <div id="my-courses">
        <leftbar-component></leftbar-component>
        <div class="content">
            <topbar-component :items="items"></topbar-component>
            <h1>我的订单</h1>
            <div class="search-bar">
                <div class="course-entrybox">
                    <i class="icon-search"></i>
                    <span>订单号</span>
                    <input v-model="orderId" type="text">
                </div>
                <div class="calendar">
                    <el-date-picker
                            popper-class="popDateBox"
                            v-model="searchDate"
                            type="daterange"
                            range-separator="~"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <div class="btn-search" @click="getTableData(1)">查询</div>
            </div>
            <div class="table">
                <el-table
                        :data="orderList"
                        style="width: 100%">
                    <el-table-column
                        prop="orderId"
                        label="ID"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="courseName"
                        label="课程名称">
                    </el-table-column>
                    <el-table-column
                        prop="orderTime"
                        label="下单时间">
                        <template slot-scope="scope">
                            {{ scope.row.createTime | formatDate }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        label="订单状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status === 0" style="color: #DC000C;">待支付</span>
                            <span v-if="scope.row.status === 1" style="color: #1DC084;">已支付</span>
                            <span v-if="scope.row.status === 2" style="color: #666666;">支付失败</span>
                        </template>
                    </el-table-column>
                </el-table>
                <listH5Component
                    :list="orderList"
                >
                    <template v-slot:title="rowData" >
                        {{rowData.prop.courseName}}
                    </template>
                    <template v-slot:datetime="rowData" >
                        {{rowData.prop.createTime | formatDateTime}}
                    </template>
                    <template v-slot:status="rowData" >
                        <span v-if="rowData.prop.status === 0" style="color: #DC000C;">待支付</span>
                        <span v-if="rowData.prop.status === 1" style="color: #1DC084;">已支付</span>
                        <span v-if="rowData.prop.status === 2" style="color: #666666;">支付失败</span>
                    </template>
                </listH5Component>
                <div class="pagination" v-if="orderListTotal > 10">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="getTableData"
                        :current-page.sync="orderListPage"
                        :page-size="orderListPageSize"
                        :total="orderListTotal">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import LeftbarComponent from './component/leftbarComponent'
    import TopbarComponent from './component/topbarComponent'
    import {urls} from '@/lib/common';

    import listH5Component from './component/listH5Component'

    export default {
        name: "MyOrders",
        components: {
            LeftbarComponent,
            TopbarComponent,
            listH5Component
        },
        data() {
            return {
                items: [
                    {
                        text: '个人中心',
                        disabled: false,
                        href: '/userCenter',
                    }, {
                        text: '我的订单',
                        disabled: true,
                        href: '/myCourses',
                    }
                ],

                orderList: [],
                orderListPage: 1,
                orderListPageSize: 10,
                orderListTotal: 0,
                orderId: '',
                searchDate: [],

            }
        },
        created() {
            this.getTableData();
        },
        methods: {
            getTableData: function(page = 1) {
                let that = this;

                let params = {
                    orderId: that.orderId,
                    startDate: that.searchDate[0] ? `${this.dateFormat(that.searchDate[0])} 00:00:00` : '',
                    endDate: that.searchDate[1] ? `${this.dateFormat(that.searchDate[1])} 23:59:59` : '',
                    pageCurrent: page,
                    pageSize: that.orderListPageSize,
                };

                that.$http.get(urls.userServer.orderFrontList, {params}).then((res) => {
                    if(res.data.status === 200) {
                        that.orderList = res.data.data.records;
                        that.orderListTotal = res.data.data.total;
                    }
                });
            },

            dateFormat(date) {
                const y = date.getFullYear();
                const m = date.getMonth() + 1;
                const d = date.getDate();
                const month = m >= 10 ? m : '0' + m;
                const day = d >= 10 ? d : '0' + d;
                return `${y}-${month}-${day}`
            },
        }
    }
</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    #my-courses {
        display: flex;
        justify-content: flex-start;
        .content {
            width: 84%;
            box-sizing: border-box;
            background: #F2FCFF;
            padding-bottom: 5%;

            >h1 {
                font-size: 1.25vw;
                margin: 1vw 0 1vw 2vw;
            }
            .search-bar {
                margin: 1vw 0 1vw 2vw;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 0.73vw;
                .course-entrybox {
                    background-color: #fff;
                    margin-right: 10px;
                    line-height: 40px;
                    border-radius: 4vw;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: #666;
                    .icon-search {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        background: url("../../assets/images/user-center/icon_search_dark.png") no-repeat center center;
                        background-size: 100%;
                        margin-left: 10px;
                    }
                    span {
                        display: inline-block;
                        margin-left: 10px;
                        padding-right: 10px;
                        border-right: 1px solid #eaeaea;
                    }
                    input {
                        width: 200px;
                        padding: 0 15px 0 10px;
                    }
                }
                .calendar {
                    margin-right: 10px;
                    @{deep} .el-date-editor {
                        border: 0;
                        border-radius: 100px;
                    }
                }
                .btn-search {
                    background-color: #24CEFF;
                    color: #fff;
                    line-height: 40px;
                    padding: 0 1.5vw;
                    cursor: pointer;
                    border-radius: 100px;
                    transition: all .3s linear;
                    &:hover {
                        background-color: #129DC6;
                    }
                }
            }
            .table {
                width: 90%;
                margin: 1vw 0 1vw 2vw;
                @{deep} .el-table {
                    &::before {
                        width: 0;
                        height: 0;
                    }
                    thead {
                        th {
                            background-color: #24CEFF;
                            color: #fff;

                        }
                        th:first-child {
                            border-radius: 10px 0 0 0;
                        }
                        th:nth-child(4) {
                            border-radius: 0 10px 0 0;
                        }
                    }
                    tbody {
                        .el-table__row:last-child {
                            border-radius: 0 0 10px 10px;
                            border-bottom: 0;
                            td:first-child {
                                border-radius: 0 0 0 10px;
                            }
                            td:last-child {
                                border-radius: 0 0 10px 0;
                            }
                        }
                    }
                }
                .pagination {
                    text-align: right;
                    margin: 1vw 0 0 0;
                    @{deep} {
                        .el-pagination {
                            .el-pager li {
                                background-color: #fff;
                                &.active {
                                    background-color: #24CEFF;
                                }
                            }
                            .btn-next,
                            .btn-prev {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #my-courses {
            flex-direction: column;
            background: #F2FCFF;
            .content{
                width: 100%;
                margin-top:-40%;
                background: none;
                .topbar{
                    display: none;
                }
                &>h1{
                    background: #fff;
                    width: 94.7%;
                    margin:0 auto;
                    .fontFn(10,border-top-left-radius);
                    .fontFn(10,border-top-right-radius);
                    padding: 0 30px;
                    color:#34254C;
                    font-weight: bold;
                    .fontFn(18);
                    .fontFn(48,height);
                    .fontFn(48,line-height);
                }
                &>.search-bar{
                    background: #fff;
                    flex-direction: column;
                    .fontFn(10,border-bottom-left-radius);
                    .fontFn(10,border-bottom-right-radius);
                    width: 94.7%;
                    margin:0 auto;
                    padding:0 26px 3%;
                    margin-bottom: 5%;
                    box-shadow: 0px 5px 10px #D5F5FF;
                    
                    .course-entrybox{
                        .fontFn(20,border-radius);
                        width: 100%;
                        background: #F3F3F3;
                        margin-right: 0;
                        margin-bottom: 5%;
                        .fontFn(38,line-height);
                        .icon-search{
                            .fontFn(18,width);
                            .fontFn(18,height);
                            margin-left: 16px;
                        }
                        span{
                            color:#555859;
                            .fontFn(14);
                            .fontFn(26,line-height);
                        }
                        input{
                            width: auto;
                            flex-grow:1;
                            .fontFn(14);
                        }
                    }
                    .course-status-options{
                        width: 100%;
                        margin-bottom: 5%;

                        @{deep} .el-select{
                            width: 100%;
                        }

                        @{deep} .el-input__inner{
                            padding: 0 32px 0 16px;
                            .fontFn(14);
                            .fontFn(20,border-radius);
                            border:none;
                            background: #F3F3F3;
                            .fontFn(38,height);
                            .fontFn(38,line-height);
                        }
                    }
                    .calendar{
                        width: 100%;
                        margin:0 auto 5%;
                        .el-date-editor{
                            padding: 0;
                            width: 100%;
                            background: #F3F3F3;
                            .fontFn(20,border-radius);
                            @{deep} .el-range__icon{
                                .fontFn(18);
                                height: 32px;
                                margin-left: 16px;
                            }
                            @{deep} .el-range-separator{
                                height: 10px;
                                line-height: 10px;
                            }
                            @{deep} .el-range-input{
                                .fontFn(38,line-height);
                                .fontFn(14);
                            }
                        }
                    }
                    .btn-search{
                        width: 100%;
                        text-align: center;
                        .fontFn(14);
                        .fontFn(38,height);
                        .fontFn(38,line-height);
                        margin-bottom: 5%;
                    }
                    
                }

                .table{
                    .el-table{
                        display: none;
                    }
                    width:94.7%;
                    margin:0 auto;
                }

                .el-dialog__wrapper{
                    @{deep} .el-dialog{
                        width: 96% !important;
                    }

                    @{deep} .el-dialog__body{
                        padding:20px 10px;
                    }

                    @{deep} .el-form-item__label{
                        width: 80px !important;
                    }

                    @{deep} .el-form-item__content{
                        margin-left:80px !important;
                    }

                    @{deep} .el-textarea__inner{
                        min-height: 120px !important;
                    }

                    @{deep} .el-row{
                        img{
                            width: 320px !important;
                            display: block;
                            margin:0 auto;
                        }
                    }
                }

                .dialog{
                    .courseware{
                        label{margin-bottom:3%;}
                        .courseware-form{
                            .search-bar{
                                flex-direction: column;
                                &>span{
                                    display: none;
                                }
                                .el-input{
                                    margin-bottom: 3%;
                                }
                                .el-range-editor.el-input__inner{
                                    width: 100% !important;
                                    margin:0 auto 3% !important;
                                }
                                .el-button{
                                    width: 100%;
                                    margin-bottom: 3%;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
</style>

<style lang="less">
@deep: ~'>>>';
@media screen and (max-width: 750px) {
    .el-dialog__wrapper{
        .el-dialog{
            width: 96% !important;
        }

        .vue-cropper{
            width: 100% !important;
        }

        .cropper-crop-box{
            transform: translate3d(0,0,0) !important;
        }
    }

    .el-message-box{
        width: 96%;
    }

    .popDateBox{
        width: auto !important;
        top:2% !important;
        left:2% !important;
        right:2% !important;
        margin:0 !important;
        background:rgba(0,0,0,0.5);

        .popper__arrow{
            display: none;
        }

        .el-picker-panel__body{

            min-width: auto !important;
            background: #fff !important;
        }

        .el-date-range-picker__content{
            float:none !important;
            width: 100% !important;
        }
    }
}
</style>